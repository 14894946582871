import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faSquareTwitter,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faCircleInfo,
  faMapLocationDot,
  faMinus,
  faPlus,
  faQuoteLeft,
  faQuoteRight,
  faSquareEnvelope,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faSquareTwitter,
  faTwitter,
  faYoutube,
);
library.add(faAddressCard);
library.add(
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faCircleInfo,
  faMapLocationDot,
  faMinus,
  faPlus,
  faQuoteLeft,
  faQuoteRight,
  faSquareEnvelope,
  faXmark
);
dom.watch();

