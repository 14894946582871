import "bootstrap";
import "cocoon";

import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

import "~/application/fontawesome-icons";
import "~/stylesheets/application.scss";
import "@fontsource/libre-franklin";
import "@fontsource/libre-franklin/600.css";
import "@fontsource/poppins/700.css";

const application = Application.start();
const controllers = import.meta.glob("../**/*_controller.js", { eager: true });
registerControllers(application, controllers);
